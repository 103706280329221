// Colors
// $vocab-1: #00507d;
// $vocab-2: #00780f;
// $vocab-3: #8c2814;
// $phrases-1: #d76923;
// $phrases-2: #782864;
// $phrases-3: #0064b4;
// $sentences-1: #007896;
// $sentences-2: #5f5596;
// $sentences-3: #468c5a;
// $sentences-4: #a03c5a;
// $phonics-1: #463c64;
// $phonics-2: #648232;
// $speech-sounds: #c84b00;
// $combining-sounds: #146450;
// $saying-words-1: #005aa0;
// $saying-words-2: #9b005a;
// $saying-words-3: #008746;
// $longer-words: #007896;
// $first-sums: #a52814;

$dse-blue: #005aaf !default;
$dse-green: #008c28 !default;
$dse-orange: #f55a05 !default;

// Social
$facebook-color: #1877f2;
$twitter-color: #1d9bf0;
$linkedin-color: #0a66c2;

$email-color: var(--primary);

$guidance-th: (
  "vocabulary": #00468c,
  "sight-words": #6e143c,
  "goals": #007828,
  "guidance": #a02d00,
  "extensions": #008278
);

$guidance-td: (
  "vocabulary": #d4e1ee,
  "sight-words": #f1d6e2,
  "goals": #e4f8eb,
  "guidance": #f5ddd4,
  "extensions": #d4e9e7
);

$health-bg: #e6e6f0;
$health-text: #2e2d4d;

$social-bg: #f2cfec;
$social-text: #6f1f63;

$motor-bg: #d4ece2;
$motor-text: #1f4736;

$language-bg: #f5f4ff;
$language-text: #664dba;
$language-text-dark: #ccc5ff;
$language-bg-dark: #0a0024;

$speech-bg: #f0f7ff;
$speech-text: #366593;
$speech-text-dark: #a3d1ff;
$speech-bg-dark: #000f21;

$reading-bg: #ebfceb;
$reading-text: #257306;
$reading-text-dark: #90df7d;
$reading-bg-dark: #021000;

$number-bg: #fcf4f6;
$number-text: #a53d59;
$number-text-dark: #ffbbc7;
$number-bg-dark: #1b0006;

$memory-bg: #e3fefa;
$memory-text: #027069;
$memory-text-dark: #86dcd1;
$memory-bg-dark: #001513;

$pathway-colors: (
  "health": $health-text,
  "social": $social-text,
  "motor": $motor-text,
  "language": $language-text,
  "speech": $speech-text,
  "reading": $reading-text,
  "number": $number-text,
  "memory": $memory-text
);

$pathway-colors-dark: (
  "language": $language-text-dark,
  "speech": $speech-text-dark,
  "reading": $reading-text-dark,
  "number": $number-text-dark,
  "memory": $memory-text-dark
);

$pathway-bg-colors: (
  "health": $health-bg,
  "social": $social-bg,
  "motor": $motor-bg,
  "language": $language-bg,
  "speech": $speech-bg,
  "reading": $reading-bg,
  "number": $number-bg,
  "memory": $memory-bg
);

$pathway-bg-colors-dark: (
  "language": $language-bg-dark,
  "speech": $speech-bg-dark,
  "reading": $reading-bg-dark,
  "number": $number-bg-dark,
  "memory": $memory-bg-dark
);

// Page Sharing Tools
$share-tools-padding-top: var(--s-2);
$share-tools-padding-bottom: var(--s-3);

// Hamburger
$hamburger-padding-x: 0.15em;
$hamburger-padding-y: 0.2em;
$hamburger-layer-width: 1.5em;
$hamburger-layer-height: 0.125em;
$hamburger-layer-spacing: 0.25em;
$hamburger-layer-border-radius: 0.25em;
$hamburger-hover-opacity: 0.75;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-disappear-breakpoint: md;

// Sidebar

// Sidebar Title
$sidebar-title-color: var(--dark);
$sidebar-title-color-dark: var(--gray-100);
$sidebar-title-font-size: var(--text-md);
$sidebar-title-border-color: var(--border-color);
$sidebar-title-border-color-dark: var(--border-color);
$sidebar-title-padding-top: 0.125rem;
$sidebar-title-padding-bottom: var(--s-1);
$sidebar-title-margin-bottom: 0.75rem;
$sidebar-title-font-weight: var(--font-semibold);

// Sidebar Items
$sidebar-font-weight: var(--font-normal);
$sidebar-font-size: var(--text-md);
$sidebar-link-color: var(--gray-800);
$sidebar-link-color-dark: var(--gray-400);
$sidebar-link-hover-color: var(--black);
$sidebar-link-hover-color-dark: var(--white);
$sidebar-link-background-color: rgba(var(--primary-rgb), 0.1);
$sidebar-link-background-color-dark: rgba(var(--primary-rgb), 0.1);
$sidebar-link-border-radius: var(--border-radius);
$sidebar-item-left-indent: var(--s-3);
$sidebar-item-padding: 0.125rem 0 0.125rem to-rem(5);
$sidebar-active-link-color: $sidebar-link-hover-color;
$sidebar-active-link-color-dark: $sidebar-link-hover-color-dark;
$sidebar-active-link-font-weight: var(--font-semibold);

// Sidebar Collapse Button
$sidebar-collapse-button-color: $sidebar-link-color;
$sidebar-collapse-button-color-dark: $sidebar-link-color-dark;
$sidebar-collapse-button-hover-color: $sidebar-link-hover-color;
$sidebar-collapse-button-hover-color-dark: $sidebar-link-hover-color-dark;

// Table of Contents

// Title
$toc-title-color: $sidebar-title-color;
$toc-title-color-dark: $sidebar-title-color-dark;
$toc-title-font-size: var(--text-md);
$toc-title-font-weight: $sidebar-title-font-weight;
$toc-title-border-color: $sidebar-title-border-color;
$toc-title-border-color-dark: $sidebar-title-border-color-dark;
$toc-title-padding-top: $sidebar-title-padding-top;
$toc-title-padding-bottom: $sidebar-title-padding-bottom;
$toc-title-margin-bottom: $sidebar-title-margin-bottom;

// Item
$toc-item-indent: var(--s-2);
$toc-item-padding-y: var(--s-1);
$toc-active-item-border-width: 0.125rem;
$toc-active-item-color: var(--black);
$toc-active-item-color-dark: var(--white);
$toc-active-item-border-color: rgba(var(--primary-rgb), 0.8);
$toc-active-item-border-color-dark: rgba(var(--primary-rgb), 0.8);
$toc-item-font-size: var(--text-md);
$toc-item-font-color: var(--gray-700);
$toc-item-font-color-dark: var(--gray-400);

// Grids
$left-sidebar-display-bp: 992px;
$right-sidebar-display-bp: 1200px;
$grid-gap: var(--s-3);
$grid-xl-gap: calc($grid-gap * 2);

// Message Banner
$message-banner-padding: 0.4rem 2rem 2rem 2rem;
$message-banner-background-color: var(--gray-200);
$message-banner-border-width: 1px;
$message-banner-border-color: var(--border-color);
$message-banner-border-radius: 0.5rem;

// Site Dropdown

$site-dropdown-header-color: var(--gray-700);
$site-dropdown-header-color-hover: var(--black);
$site-dropdown-header-color-disabled: var(--gray-500);
$site-dropdown-color: var(--gray-500);
$site-dropdown-color-hover: var(--gray-800);
$site-dropdown-color-disabled: var(--gray-400);
$site-dropdown-background-color-hover: var(--gray-50);

$site-dropdown-color-dark: var(--gray-400);
$site-dropdown-color-hover-dark: var(--gray-200);
$site-dropdown-color-disabled-dark: var(--gray-600);
$site-dropdown-header-color-dark: var(--gray-300);
$site-dropdown-header-color-hover-dark: var(--white);
$site-dropdown-header-color-disabled-dark: var(--gray-500);
$site-dropdown-background-color-hover-dark: var(--gray-800);

$site-dropdown-border-color: var(--border-color);
$site-dropdown-border-width: 2px;
$site-dropdown-border-radius: var(--border-radius-lg);
$site-dropdown-caret-scale: 1.5;

// Badges
$preview-badge-color: var(--orange-700);
$preview-badge-color-dark: var(--orange-500);
$preview-badge-background-color: var(--orange-50);
$preview-badge-background-color-dark: var(--orange-950);

$new-badge-color: var(--indigo-600);
$new-badge-color-dark: var(--indigo-400);
$new-badge-background-color: var(--indigo-50);
$new-badge-background-color-dark: var(--indigo-950);

$coming-soon-badge-color: var(--gray-600);
$coming-soon-badge-color-dark: var(--gray-400);
$coming-soon-badge-background-color: var(--gray-50);
$coming-soon-badge-background-color-dark: var(--gray-950);
