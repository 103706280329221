.stepper {
  --size: 3rem;
  --bar-width: 8px;
  --color-active: #fff;
  --background-color: var(--gray-100);
  --background-color-active: var(--primary);
  --translation-offset: calc(calc(var(--size) / 2) - calc(var(--bar-width) / 2));

  display: flex;
  flex-wrap: wrap;
  height: auto;

  counter-reset: stepper;

  &:not(.stepper-vertical) {
    .stepper-item {
      flex: 1;
      flex-direction: column;
      text-align: center;

      &::before {
        width: var(--size);
        height: var(--size);
        margin-inline: auto;
        margin-bottom: 0.5rem;
      }

      &::after {
        top: 0;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: var(--bar-width);
        transform: translateY(var(--translation-offset));
      }
    }
  }
}

.stepper-item {
  position: relative;
  display: flex;

  &::before {
    z-index: 1;
    display: grid;
    place-items: center;
    font-weight: 600;
    color: var(--color, inherit);
    content: counter(stepper);
    counter-increment: stepper;
    background-color: var(--background-color);
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    content: "";
    background-color: var(--background-color);
  }

  &.active {
    --background-color: var(--background-color-active);
    --color: var(--color-active);
  }

  &:last-child::after {
    display: none;
  }
}

.stepper-vertical {
  --spacing: 4rem;

  flex-direction: column;

  .stepper-item {
    gap: 1rem;
    padding-bottom: var(--spacing);

    // Circles
    &::before {
      flex: 0 0 var(--size);
      height: var(--size);
    }

    // Line separators
    &::after {
      top: var(--size);
      bottom: calc(var(--size) * -0.5); // Ensures bars are long enough when aligning items center
      left: 0;
      width: var(--bar-width);
      transform: translateX(var(--translation-offset));
    }
  }
}
