.grid-area-sidebar-left {
  grid-area: sidebar-left;
}

.grid-area-intro {
  grid-area: intro;
}

.grid-area-sidebar-right {
  --sidebar-display: none;
  display: var(--sidebar-display);

  grid-area: sidebar-right;
}

.grid-area-content {
  grid-area: content;
  max-width: var(--mw-prose);
  width: 100%;
  margin-inline: auto;

  p {
    margin-block-end: 1.25em;

    &:not(.lead) {
      line-height: var(--prose-line-height);
    }
  }

  li {
    margin-block: 0.5em;
  }
}

@media screen and (min-width: $right-sidebar-display-bp) {
  // Overrides the "display: none" on the right sidebar to force it to show
  .grid-area-sidebar-right {
    --sidebar-display: block;
  }
}
