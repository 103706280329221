// stylelint-disable selector-no-qualifying-type
th.guidance,
td.guidance {
  border-color: var(--white);
}

td.guidance ul {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

th.guidance {
  color: var(--white);
  background-color: var(--primary-700);
}

td.guidance {
  color: var(--black);
  background-color: var(--gray-100);
}

@each $section, $color in $guidance-th {
  th.guidance-#{$section} {
    background-color: $color;
  }
}

@each $section, $color in $guidance-td {
  td.guidance-#{$section} {
    background-color: $color;
  }
}
