.highlight {
  &.client-id {
    white-space: nowrap;
    @include border-radius(2rem);

    &.client-id-icon::before {
      content: "\F4D7";
      color: $dse-orange;
      margin-top: 2px;
      margin-right: 0.375rem;
      font-family: bootstrap-icons !important; // stylelint-disable-line
    }
  }
}

.btn-primary .highlight,
.btn-secondary .highlight,
.btn-success .highlight,
.btn-info .highlight,
.btn-warning .highlight,
.btn-danger .highlight,
.btn-dark .highlight,
.bg-primary .highlight,
.bg-secondary .highlight,
.bg-success .highlight,
.bg-info .highlight,
.bg-warning .highlight,
.bg-danger .highlight,
.bg-dark .highlight {
  &.client-id-icon::before {
    color: #fff;
  }
}
