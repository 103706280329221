.badge-preview {
  --color: #{$preview-badge-color};
  --background-color: #{$preview-badge-background-color};

  color: var(--color);
  background-color: var(--background-color);
}

@include color-mode(dark) {
  .badge-preview {
    --color: #{$preview-badge-color-dark};
    --background-color: #{$preview-badge-background-color-dark};
  }
}

.badge-new {
  --color: #{$new-badge-color};
  --background-color: #{$new-badge-background-color};

  color: var(--color);
  background-color: var(--background-color);
}

@include color-mode(dark) {
  .badge-new {
    --color: #{$new-badge-color-dark};
    --background-color: #{$new-badge-background-color-dark};
  }
}

.badge-coming-soon {
  --color: #{$coming-soon-badge-color};
  --background-color: #{$coming-soon-badge-background-color};

  color: var(--color);
  background-color: var(--background-color);
}

@include color-mode(dark) {
  .badge-coming-soon {
    --color: #{$coming-soon-badge-color-dark};
    --background-color: #{$coming-soon-badge-background-color-dark};
  }
}
