// To create a scrollbar for overflowing tables, wrap table in div.table-container
.table-container {
  width: 100%;
  overflow-x: scroll;
}

@include media-breakpoint-up(md) {
  .table-container {
    overflow: auto;
  }
}

@media print {
  // Hide scrollbars when printing
  .table-container {
    overflow: auto;
  }
}
