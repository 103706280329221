.contact-grid {
  --grid-template-columns: 1fr;
  --grid-template-areas: "header" "form" "addresses";

  grid-template-columns: var(--grid-template-columns);
  grid-template-areas: var(--grid-template-areas);
}

@include media-breakpoint-up(lg) {
  .contact-grid {
    --grid-template-columns: repeat(2, 1fr);
    --grid-template-areas: "header form" "addresses form";

    grid-template-rows: auto 1fr;
  }
}

.contact-grid-header {
  grid-area: header;
}

.contact-grid-form {
  grid-area: form;
}

.contact-grid-addresses {
  grid-area: addresses;
}
