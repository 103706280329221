.message-banner {
  padding: $message-banner-padding;
  background-color: $message-banner-background-color;
  border: $message-banner-border-width solid $message-banner-border-color;
  border-radius: $message-banner-border-radius;
}

.message-banner-green {
  background-color: var(--gray-50);
  border-color: var(--success-700);
}

.message-banner-btn-container {
  height: 2rem;
}
