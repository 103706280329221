#client-panel,
#client-panel-dropdown,
#client-panel-dropdown-menu {
  font-size: var(--text-sm);
  color: var(--gray-700);
}

.btn-clients {
  @include button-variant(
    $background: $gray-50,
    $border: $gray-300,
    $color: $gray-700,
    $hover-background: $gray-200,
    $hover-color: $gray-800,
    $active-background: $gray-200,
    $active-color: $gray-800
  );
}

#client-panel-dropdown-picture {
  height: 32px;
  margin-right: var(--s-3);
  border-radius: var(--border-radius);
}

#client-panel-display-name {
  margin: var(--s-2);
  overflow: hidden;
  font-weight: var(--font-bold);
}

#client-panel-login-name {
  margin: var(--s-2);
  overflow: hidden;
  font-size: var(--text-xxs);
}

#client-panel-logo {
  height: 1.5rem;
}

#client-panel-picture {
  height: 72px;
  border-radius: var(--border-radius-lg);
}

#client-panel-dropdown-menu-header {
  padding: 0 var(--s-3);
}

#client-panel-dropdown-menu-content {
  padding: var(--s-3);
}

#client-panel-dropdown-menu {
  min-width: 300px;
}

@include media-breakpoint-up(sm) {
  #client-panel-dropdown-menu {
    min-width: 400px;
  }
}

@include media-breakpoint-up(md) {
  #client-panel-dropdown-menu {
    min-width: 25rem;
  }
}
