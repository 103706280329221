@include color-mode(dark) {
  .toc {
    --item-color: #{$toc-item-font-color-dark};
    --active-item-color: #{$toc-active-item-color-dark};
    --active-item-border-color: #{$toc-active-item-border-color-dark};
    --title-color: #{$toc-title-color-dark};
    --title-border-color: #{$toc-title-border-color-dark};
  }
}

.toc {
  --offset: var(--s-4);
  --item-color: #{$toc-item-font-color};
  --active-item-color: #{$toc-active-item-color};
  --active-item-border-color: #{$toc-active-item-border-color};
  --title-color: #{$toc-title-color};
  --title-border-color: #{$toc-title-border-color};

  position: sticky;
  top: var(--offset);
  max-height: calc(100vh - var(--offset));
  font-size: $font-size-nav;

  .nav-link {
    padding-block: $toc-item-padding-y;
    padding-right: 0;
    padding-left: $toc-item-indent; // Sets the offset from the activated border
    font-size: $toc-item-font-size;
    color: var(--item-color);
    border-left: $toc-active-item-border-width solid transparent;
    border-radius: 0;

    &:hover,
    &.active {
      color: var(--active-item-color);
      border-left-color: var(--active-item-border-color);
    }
  }

  .toc-title {
    display: block;
    padding-top: $toc-title-padding-top;
    padding-bottom: $toc-title-padding-bottom;
    margin-bottom: $toc-title-margin-bottom;
    margin-left: $toc-item-indent; // Aligns the title
    font-size: $toc-title-font-size;
    font-weight: $toc-title-font-weight;
    color: var(--title-color);
    text-decoration: none;
    border-bottom: 1px solid var(--title-border-color);
  }
}

.toc ol,
.toc ul {
  padding-left: 0;
  margin: 0;
  list-style: none;

  // Creates indents for the sublists
  ul,
  ol {
    padding-left: $toc-item-indent;
  }
}
