.site-dropdown-item {
  position: static;

  &.disabled {
    --header-color: #{$site-dropdown-header-color-disabled};
    --text-color: #{$site-dropdown-color-disabled};

    a {
      pointer-events: none;
    }
  }
}

@include color-mode(dark) {
  .site-dropdown-item.disabled {
    --header-color: #{$site-dropdown-header-color-disabled-dark};
    --text-color: #{$site-dropdown-color-disabled-dark};
  }
}

@include color-mode(dark) {
  .site-dropdown {
    --header-color: #{$site-dropdown-header-color-dark};
    --header-hover-color: #{$site-dropdown-header-color-hover-dark};
    --text-color: #{$site-dropdown-color-dark};
    --text-hover-color: #{$site-dropdown-color-hover-dark};
    --background-hover-color: #{$site-dropdown-background-color-hover-dark};
  }
}

.site-dropdown {
  --header-color: #{$site-dropdown-header-color};
  --header-hover-color: #{$site-dropdown-header-color-hover};
  --text-color: #{$site-dropdown-color};
  --text-hover-color: #{$site-dropdown-color-hover};
  --border-width: #{$site-dropdown-border-width};
  --background-hover-color: #{$site-dropdown-background-color-hover};

  /* stylelint-disable declaration-no-important */

  position: absolute !important;
  top: calc(var(--top-offset) - calc(var(--border-width) * 2)) !important;
  left: 0 !important;
  width: 100%;
  border: 0;
  background: none;
  padding-top: 0 !important;
  margin-top: 0 !important;

  /* stylelint-enable declaration-no-important */

  &::before {
    --scale: #{$site-dropdown-caret-scale};
    --toggler-offset: 0.5rem;
    content: "\F235";
    font-size: calc(1rem * var(--scale));
    font-family: bootstrap-icons;
    position: absolute;
    inset-inline-start: calc(var(--caret-left) - var(--toggler-offset));
    inset-block-start: calc(-1em - var(--border-width) * var(--scale) + var(--border-width));
    color: $site-dropdown-border-color;
    width: 0;
    height: 0;
  }
}

.site-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  border-radius: $site-dropdown-border-radius;
  border: var(--border-width) solid var(--border-color);

  padding: var(--s-4);

  background-color: var(--body-bg);
}

.site-dropdown-link {
  --icon-opacity: 0;

  display: grid;
  gap: 0 var(--s-4);
  color: var(--text-color);
  background-color: var(--background-color, transparent);
  border-radius: var(--border-radius);

  padding: var(--s-3);

  grid-template-areas: "icon header" "icon text";
  grid-template-columns: 2rem 1fr;

  h4 {
    grid-area: header;
    color: var(--header-color);
    margin-bottom: 0.25rem;

    &::after {
      font-family: bootstrap-icons;
      content: "\F138";

      display: inline-block;
      opacity: var(--icon-opacity);

      margin-inline-start: 1em;

      line-height: 1;
      vertical-align: -0.125em;

      transition: opacity 0.2s ease-in;
    }

    p {
      margin-block-end: 0;
    }
  }

  &:hover,
  &:focus {
    --text-color: var(--text-hover-color);
    --header-color: var(--header-hover-color);
    --background-color: var(--background-hover-color);
    --icon-opacity: 1;

    text-decoration: none;
  }
}

.site-dropdown-link-icon {
  border-radius: var(--border-radius);
  grid-area: icon;
  width: 2rem;
  height: 2rem;
  margin-top: var(--s-1);
}

.site-dropdown-link-text {
  grid-area: text;
}

.site-dropdown-toggler {
  background: var(--gray-50);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--gray-300);
  color: var(--gray-700);
}
