.timeline-header {
  position: relative;
}

@include media-breakpoint-up(md) {
  .timeline-section {
    --rail-color: rgba(var(--primary-rgb), 0.15);
    --rail-width: 2px;
    --rail-padding: var(--s-4);
    padding-inline-start: var(--rail-padding);
    border-block: var(--rail-width) solid transparent;
    border-inline-start: var(--rail-width) solid var(--rail-color);
  }
  .timeline-header::before {
    position: absolute;
    bottom: 50%;
    left: calc(var(--rail-padding) * -1);
    width: calc(var(--rail-padding) * 0.66);
    height: var(--rail-width);
    content: "";
    background: var(--rail-color);
  }
}

@include media-breakpoint-up(lg) {
  .timeline-section {
    --rail-padding: 2.5rem;
  }
}
