.content-icon::before {
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: "bootstrap-icons";
  font-size: 125%;
  display: inline-block;
  margin-top: -0.25rem;
  margin-right: 0.4rem;
  vertical-align: top;
}

.content-icon-link::before {
  content: "\F1C3";
}

.content-icon-tracker::before {
  content: "\F28B";
}

.content-icon-tip::before {
  content: "\F26D";
}

.content-icon-attention::before {
  content: "\F339";
}

.content-icon-question::before {
  content: "\F50B";
}

.content-icon-idea::before {
  content: "\F46B";
}
