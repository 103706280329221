.prose {
  font-size: to-rem(16);
  line-height: 1.75;

  p {
    margin-top: em(20, 16);
    margin-bottom: em(20, 16);
  }

  .lead {
    margin-top: em(24, 20);
    margin-bottom: em(24, 20);
  }

  blockquote {
    padding-left: em(20, 20);
    margin-top: em(32, 20);
    margin-bottom: em(32, 20);
    font-style: normal;
    font-size: var(--text-lg);
    font-family: var(--font-serif);
  }

  h1 {
    margin-top: 0;
  }

  h2,
  .h2 {
    margin-top: to-rem(48);
  }

  h3,
  .h3 {
    margin-top: to-rem(32);
  }

  img {
    margin-block: em(32, 16);
  }

  video {
    margin-block: em(32, 16);
  }

  figure {
    margin-block: em(32, 16);

    > * {
      margin-block: 0;
    }
  }

  figcaption {
    margin-top: em(14);
  }

  code {
    font-size: em(14, 16);
  }

  pre {
    padding: em(14);

    margin-block: em(20);

    font-size: em(14, 16);
    line-height: 1.7;

    border-radius: to-rem(6);
  }

  ol {
    padding-left: em(26, 16);
    margin-top: em(20, 16);
    margin-bottom: em(20, 16);
  }

  ul {
    padding-left: em(26, 16);
    margin-block: em(20, 16);
  }

  li {
    margin-block: em(8, 16);
  }

  ol > li {
    padding-left: em(6, 16);
  }

  ul > li {
    padding-left: em(6, 16);
  }

  // stylelint-disable-next-line selector-max-type
  ul > li p {
    margin-block: em(12, 16);
  }

  ul > li > *:first-child {
    margin-top: em(20, 16);
  }

  ul > li > *:last-child {
    margin-bottom: em(20, 16);
  }

  ol > li > *:first-child {
    margin-top: em(20, 16);
  }

  ol > li > *:last-child {
    margin-bottom: em(20, 16);
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-block: em(12, 16);
  }

  hr {
    margin-block: em(48, 16);
  }

  hr + * {
    margin-top: 0;
  }

  h2 + * {
    margin-top: 0;
  }

  h3 + * {
    margin-top: 0;
  }

  h4 + * {
    margin-top: 0;
  }

  table {
    font-size: em(14, 16);
    line-height: 1.7;
  }

  thead th {
    padding-right: em(9);
    padding-bottom: em(9);
    padding-left: em(9);
  }

  thead th:first-child {
    padding-left: 0;
  }

  thead th:last-child {
    padding-right: 0;
  }

  tbody td,
  tfoot td {
    padding-top: em(9);
    padding-right: em(9);
    padding-bottom: em(9);
    padding-left: em(9);
  }

  tbody td:first-child,
  tfoot td:first-child {
    padding-left: 0;
  }

  tbody td:last-child,
  tfoot td:last-child {
    padding-right: 0;
  }

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
