.rli-intro-bg {
  background-color: #008bc5;
}

.rli-intro-ds-bg {
  background-color: #00907e;
}

.rli-reading-strand-bg {
  background-color: #b61f24;
}

.rli-language-strand-bg {
  background-color: #007147;
}

.rli-records-bg {
  background-color: #d94c24;
}

.rli-evaluation-bg {
  background-color: #0056a4;
}

.rli-glossary-bg {
  background-color: #006a7f;
}
