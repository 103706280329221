.article-grid {
  --grid-template-areas: "header" "meta" "authors" "body" "sharing";
  --grid-columns: 1;
  --grid-rows: auto auto auto 1fr auto;
  --gap: var(--s-3);

  position: relative;
  display: grid;
  grid-template-areas: var(--grid-template-areas);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-template-rows: var(--grid-rows);
  gap: var(--gap);
}

@include media-breakpoint-up(lg) {
  .article-grid {
    --grid-template-areas: ". header header header" ". meta meta meta" "authors body body body" "sharing body body body" ". body body body";
    --grid-columns: 4;
    --grid-rows: auto auto auto auto 1fr;
    --gap: var(--s-3);
  }

  .article-sharing {
    position: sticky;
    top: var(--s-4);
  }
}

.article-header {
  grid-area: header;
  max-width: var(--mw-prose);
}

.article-meta {
  grid-area: meta;
  max-width: var(--mw-prose);
}

.article-sharing {
  grid-area: sharing;
}

.article-authors {
  grid-area: authors;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 var(--s-2);

  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 !important; // Override library's `.article-authors` class

  .author-name {
    color: var(--emphasis-color);
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .author-title {
    color: var(--secondary);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .author-image {
    grid-column: 1 / 1;
    grid-row: 1 / 3;

    margin-block: auto;
    border-radius: 100%;
  }
}

.article-body {
  grid-area: body;

  h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol {
    max-width: var(--mw-prose);
  }
}

