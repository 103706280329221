@media screen and (min-width: $left-sidebar-display-bp) {
  .grid-sidebar-content-sidebar,
  .grid-sidebar-content {
    --grid-template-columns: 1fr 3fr;
    --grid-template-areas: "sidebar-left content";
    --gap: 0 #{$grid-gap};

    display: grid;
    grid-template-areas: var(--grid-template-areas);
    grid-template-columns: var(--grid-template-columns);
    grid-auto-rows: minmax(100px, auto);
    gap: var(--gap);
  }
}

@media screen and (min-width: $right-sidebar-display-bp) {
  .grid-sidebar-content-sidebar {
    --grid-template-areas: "sidebar-left content sidebar-right";
    --grid-template-columns: 1fr 3fr 1fr;
  }

  .grid-sidebar-content {
    --gap: 0 #{$grid-xl-gap};
  }
}
