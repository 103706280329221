#dse-navigation,
#site-header-banner,
#site-navigation,
#site-links,
#group-links,
#site-footer,
#site-footer-2,
#site-sharing-tools {
  clear: both;
  font-size: $font-size-nav;
}

#site-navigation {
  background-color: $navbar-background-color;
}

#dse-navigation {
  padding: var(--s-2) 0;
  min-height: 4rem;
}

#site-alert-banner {
  padding: 0.4rem;
  background-color: var(--primary-100);

  a {
    color: var(--white);
  }
}

#site-notices,
#site-notices .navbar {
  font-size: $font-size-nav;
  background-color: var(--warning-300);
}

@include media-breakpoint-down(lg) {
  #navbar-lang {
    display: none;
  }
}
