.slider {
  --page-width: calc(100vw - calc(#{$container-padding-x} * 2));
  --slider-max-height: 15rem;
  --slider-item-width: 20rem;

  position: relative;
  display: flex;
  width: 100%;
  max-width: 100vw;

  .slider-container {
    --overflow-margin: calc(calc(100vw / 2) - calc(var(--page-width) / 2));
    position: relative;
    display: flex;

    scroll-snap-type: x mandatory;

    scroll-padding-left: var(--overflow-margin);
    scroll-padding-right: var(--overflow-margin);

    overflow-x: scroll;
    overscroll-behavior-x: contain;

    scrollbar-width: none; // Hide scrollbar for Firefox

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-item {
    display: flex;
    scroll-snap-align: start end;
    min-width: var(--slider-item-width);

    > img {
      max-height: var(--slider-max-height);
    }
  }

  .slider-padding {
    min-width: var(--overflow-margin);
    height: 1px;
    margin: 0;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .slider {
      --page-width: #{map-get($container-max-widths, $breakpoint)};
    }
  }
}
