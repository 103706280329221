// stylelint-disable scss/dollar-variable-pattern

.section {
  --section-padding-top: var(--s-5);
  --section-padding-bottom: var(--s-5);
  --section-title-color: #{$headings-color};
  --section-text-color: #{$body-color};

  color: var(--section-text-color);
  background-color: var(--section-background-color);

  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  @include headings {
    color: var(--section-title-color);
  }
}

@include media-breakpoint-up(lg) {
  .section {
    --section-padding-top: var(--s-hero);
    --section-padding-bottom: var(--s-hero);
  }
}

.section-title {
  margin-bottom: var(--s-5);
  @include font-size($h1-font-size);
}

.section-dark,
%section-dark {
  --section-title-color: #{$headings-color-dark};
  --section-text-color: #{$body-color-dark};
  --section-text-contrast: #000;
}

.section a:not(.btn),
.section .btn-link {
  color: var(--section-color);

  &:hover {
    color: var(--section-color-hover);
  }
}

.section .btn-section {
  --btn-color: var(--section-color-contrast);
  --btn-bg: var(--section-color);
  --btn-border-color: var(--section-color);
  --btn-hover-color: var(--section-color-contrast);
  --btn-hover-bg: var(--section-color-hover);
  --btn-hover-border-color: var(--section-color-hover);
  --btn-active-color: var(--section-color-contrast);
  --btn-active-bg: var(--section-color-hover);
  --btn-focus-shadow-rgb: var(--section-color-hover-rgb);
}

.text-section-accent {
  // stylelint-disable-next-line declaration-no-important
  color: var(--section-color) !important;
}

.text-section-contrast {
  // stylelint-disable-next-line declaration-no-important
  color: var(--section-color-contrast) !important;
}

.bg-section-accent {
  // stylelint-disable-next-line declaration-no-important
  background-color: var(--section-color) !important;
}

.bg-section-contrast {
  // stylelint-disable-next-line declaration-no-important
  background-color: var(--section-color-contrast) !important;
}

@mixin accent-vars($color, $value) {
  --section-color: var(--#{$color}-700);
  --section-color-hover: var(--#{$color}-800);
  --section-color-contrast: var(--#{$color}-50);
  --section-color-hover-rgb: #{to-rgb($value)};
}

@mixin accent-dark-vars($color) {
  --section-color: var(--#{$color}-400);
  --section-color-hover: var(--#{$color}-300);
  --section-color-contrast: var(--#{$color}-950);
}

@mixin bg-vars($color) {
  --section-background-color: var(--#{$color}-50);
}

@mixin bg-dark-vars($color) {
  --section-background-color: var(--#{$color}-950);
}

$_section-themes: ("primary", "info");

@each $color in $_section-themes {
  .section-accent-#{$color},
  %section-accent-#{$color} {
    @include accent-vars($color, map-get($theme-colors, $color));
  }

  .section-dark.section-accent-#{$color},
  %section-accent-#{$color}-dark {
    @include accent-dark-vars($color);
  }

  .section-bg-#{$color},
  %section-bg-#{$color} {
    @include bg-vars($color);
  }

  .section-dark.section-bg-#{$color},
  %section-bg-#{$color}-dark {
    @include bg-dark-vars($color);
  }

  .section-#{$color} {
    @extend %section-accent-#{$color};
    @extend %section-bg-#{$color};
  }

  .section-dark.section-#{$color} {
    @extend %section-accent-#{$color}-dark;
    @extend %section-bg-#{$color}-dark;
  }
}

$_section-colors: map-remove($colors, "white", "black", "gray-dark");

@each $color, $value in $_section-colors {
  .section-accent-#{$color} {
    @include accent-vars($color, $value);
  }

  .section-dark.section-accent-#{$color} {
    @include accent-dark-vars($color);
  }

  .section-bg-#{$color} {
    @include bg-vars($color);
  }

  .section-dark.section-bg-#{$color} {
    @include bg-dark-vars($color);
  }
}
