@include color-mode(dark) {
  .btn-social {
    --color: var(--gray-400);
  }
}

.btn-social {
  --color: var(--gray-700);

  min-width: 2rem;
  min-height: 2rem;
  padding: var(--s-1) var(--s-2);
  font-size: var(--text-md);
  color: var(--color);

  &:hover {
    text-decoration: none;

    .bi-envelope-fill {
      color: $email-color;
    }

    .bi-twitter {
      color: $twitter-color;
    }

    .bi-facebook {
      color: $facebook-color;
    }

    .bi-linkedin {
      color: $linkedin-color;
    }
  }
}
