.word-example,
.sound-example,
.version-indicator {
  font-family: var(--font-sans);
  font-weight: var(--font-bold);
}

.word-example {
  color: rgb(45, 120, 170);
}

.sound-example {
  color: rgb(160, 50, 50);
}

.version-indicator {
  color: rgb(0, 120, 60);
}
