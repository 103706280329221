@media print {
  .arrow {
    display: none;
  }
}

.arrow {
  --arrow-hover-transition: 150ms ease-in;
  --arrow-hover-translation: translateX(3px);
  --arrow-head-transform: none;
  --arrow-body-opacity: 0;

  position: relative;
  bottom: 1px;
  margin-top: 0.05em;
  margin-left: var(--s-1);
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
}

.arrow .arrow-tip {
  transition: transform var(--arrow-hover-transition);
  transform: var(--arrow-head-transform);
}

.arrow .arrow-line {
  opacity: var(--arrow-body-opacity);
  transition: opacity var(--arrow-hover-transition);
}

a,
button {
  &:hover {
    .arrow {
      --arrow-body-opacity: 1;
      --arrow-head-transform: var(--arrow-hover-translation);
    }
  }
}
