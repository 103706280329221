@media print {
  body {
    line-height: 1.625;
    color: #000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
  }

  .hidden-print {
    display: none;
  }

  [href]::after {
    content: "";
  }

  #dse-navigation,
  #site-navigation,
  #site-sharing-tools,
  #site-links,
  #group-links,
  #page-sharing-tools,
  #site-follow-tools,
  #site-donate-footer,
  #cookie-consent {
    display: none;
  }
}
