// stylelint-disable selector-no-qualifying-type
caption {
  font-weight: var(--font-semibold);
  color: var(--body);
  caption-side: bottom;
  border-bottom: 1px solid var(--border-color);
}

ul {
  padding-left: 1.25rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-top: 0;
}

.list-header {
  font-weight: var(--font-semibold);
}

abbr[title] {
  text-decoration: none;
}

caption h1,
caption h2,
caption h3,
caption h4,
.article-figure h1,
.article-figure h2,
.article-figure h3,
.article-figure h4 {
  font-size: var(--text-lg);
  color: var(--dark);
}

.lead-big {
  max-width: 60rem;
  padding: var(--s-3) 0;
  margin: var(--s-3) auto;
  font-size: var(--text-xxl);
  text-align: center;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.presentation {
  @include headings {
    font-weight: var(--font-normal);
  }
}

.presentation h1 {
  font-size: 2.2rem;
}

.presentation h2 {
  font-size: 1.7rem;
}

.presentation p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.presentation .row {
  margin: var(--s-4) 0;
}

.smaller {
  font-size: 0.75em;
}

#site-sharing-tools {
  padding: 0.875rem 0 0.4rem;
}

.well {
  padding: var(--s-3);
  margin: var(--s-3) 0;
  background-color: var(--gray-50);
  border: 1px solid var(--border-color);
  border-radius: 0.6rem;
}

#article {
  margin-bottom: var(--s-4);
}

#message-banner-top-section,
#message-banner-bottom-section {
  margin: var(--s-3) 0;
}

.content-banner {
  padding: var(--s-3);
  color: var(--primary-800);
  background-color: var(--gray-50);
}

.content-banner h1,
.content-banner h2,
.content-banner h3,
.content-banner h4,
.content-banner h5,
.content-banner h6 {
  color: var(--primary-800);
}

.header-block {
  width: 75%;
  min-width: 320px;
  max-width: 60em;
  margin: var(--s-3) auto 2rem;
  text-align: center;

  p {
    font-size: 1.25rem;
    letter-spacing: -0.1px;
  }
}

.intervention-summary {
  font-size: var(--text-sm);
  border-color: var(--primary);
}

.intervention-card-header {
  padding-left: 0.875rem;
  margin-top: -1px;
  font-weight: var(--font-semibold);
  color: var(--white);
  background-color: var(--primary);
}

.intervention-summary th {
  padding-left: 0.875rem;
  border-right: 1px solid var(--border-color);
}

.intervention-summary table ul {
  margin: 0;
  list-style: square;
}

.article-authors {
  margin: 2rem 2rem 2rem 0;
  font-size: var(--text-lg);
}

.article-abstract {
  padding-left: var(--s-3);
  border-left: 0.2rem solid var(--border-color);
}

.article-source-ref {
  padding: 0.4rem 0;
  margin: var(--s-3) 0;
  font-size: var(--text-sm);
}

ul.references {
  padding-left: 0;
  font-size: var(--text-sm);
  list-style: none;
}

div.survey-question {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

label.survey-question,
p.survey-question {
  font-size: var(--text-lg);
  font-weight: var(--font-medium);
}

.forum-author {
  font-weight: var(--font-medium);
}

.forum-contact {
  font-size: var(--text-sm);
}

.forum-affiliations {
  padding-left: var(--s-3);
  font-size: var(--text-sm);
}

.forum-in-abstract-list {
  padding-left: var(--s-3);
}

.forum-in-abstract-references {
  padding-left: var(--s-3);
  font-size: var(--text-xxs);
}

.article-table,
.article-figure {
  padding: var(--s-3);
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: var(--text-sm);
  border: 1px solid var(--border-color);

  h2 {
    font-size: var(--text-md);
  }
}

.dsrp-blockquote {
  border-left: 2px solid var(--border-color);
}

.highlight-info {
  color: var(--success);
  border: 1px solid var(--success);
  border-radius: var(--border-radius);
}

table.data td,
table.data th {
  font-size: var(--text-sm);
  line-height: 140%;
}
