@include color-mode(dark) {
  .sidebar-title-block {
    --sidebar-title-color: #{$sidebar-title-color-dark};
  }
}
.sidebar-title-block {
  --sidebar-title-color: #{$sidebar-title-color};

  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: $sidebar-title-padding-top;
  padding-bottom: $sidebar-title-padding-bottom;
  margin-top: var(--s-1);
  margin-bottom: var(--s-3);
  border-bottom: 1px solid $sidebar-title-border-color;

  .sidebar-title {
    font-size: $sidebar-title-font-size;
    font-weight: $sidebar-title-font-weight;
    color: var(--sidebar-title-color);
    text-decoration: none;
  }
}

@include color-mode(dark) {
  .sidebar-nav {
    --sidebar-link-color: #{$sidebar-link-color-dark};
    --sidebar-link-hover-color: #{$sidebar-link-background-color-dark};
    --sidebar-active-link-color: #{$sidebar-active-link-color-dark};
  }
}

.sidebar-nav {
  --sidebar-link-color: #{$sidebar-link-color};
  --sidebar-link-hover-color: #{$sidebar-link-background-color};
  --sidebar-active-link-color: #{$sidebar-active-link-color};

  ul,
  ol {
    padding-left: 0;
    margin-bottom: var(--s-1);
    list-style: none;
  }

  // All sidebar links
  a {
    display: inline-flex;
    width: 100%;
    color: var(--sidebar-link-color);
    text-decoration: none;
    border-radius: $sidebar-link-border-radius;

    &:hover,
    &:focus {
      background-color: var(--sidebar-link-hover-color);
    }
  }

  // Top level sidebar links with no children
  // stylelint-disable-next-line selector-max-type
  > ol > li > a {
    padding-left: var(--s-4);
    margin-left: 0;
  }

  .active {
    font-weight: $sidebar-active-link-font-weight;
    color: var(--sidebar-active-link-color);
  }
}

.btn-toggle,
.btn-toggle-nav {
  --color: #{$sidebar-link-color};

  font-size: $sidebar-font-size;
  font-weight: $sidebar-font-weight;
  color: var(--color);
}

// Dropdown buttons
@include color-mode(dark) {
  .btn-toggle {
    --hover-color: #{$sidebar-link-hover-color-dark};
    --background-color: #{$sidebar-link-background-color-dark};
  }
}

.btn-toggle {
  --hover-color: #{$sidebar-link-hover-color};
  --background-color: #{$sidebar-link-background-color};

  display: inline-flex;
  align-items: center;
  width: 100%;

  // Spacing
  padding: $sidebar-item-padding;
  margin-bottom: var(--s-2);
  background-color: transparent;

  // Border
  border: 0;
  border-radius: $sidebar-link-border-radius;

  &:hover {
    color: var(--hover-color);
    background-color: var(--background-color);
  }

  // Toggle Icon
  &::before {
    width: 1.25em;
    margin-left: 0.0625rem;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
  }

  &[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }
}

// The list of an item's children
.btn-toggle-nav {
  padding-right: $sidebar-item-left-indent;
  padding-bottom: var(--s-1);
  padding-left: 0;
  list-style: none;

  // Child sidebar links
  a {
    padding: $sidebar-item-padding;
    margin-left: $sidebar-item-left-indent;
  }
}

// The button shown on mobile to collapse the sidebar navigation

@include color-mode(dark) {
  .btn-sidebar-toggle {
    --color: #{$sidebar-collapse-button-color-dark};
    --hover-color: #{$sidebar-collapse-button-hover-color-dark};
  }
}

.btn-sidebar-toggle {
  --color: #{$sidebar-collapse-button-color};
  --hover-color: #{$sidebar-collapse-button-hover-color};

  padding: 0.1rem var(--s-1);
  margin-bottom: var(--s-1);
  color: var(--color);

  svg {
    margin: 0.2rem;
  }

  &:hover,
  &:focus {
    color: var(--hover-color);
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.3);
  }

  .bi-collapse {
    display: none;
  }

  &:not(.collapsed) .bi-expand {
    display: none;
  }

  &:not(.collapsed) .bi-collapse {
    display: inline-block;
  }
}

@media screen and (min-width: $left-sidebar-display-bp) {
  .sidebar {
    --offset: var(--s-4);

    position: sticky;
    top: var(--offset);
    display: block;
    height: subtract(100vh, var(--offset));

    // Stops the left side of buttons from cutting off
    padding-left: var(--s-1);
    margin-left: calc(var(--s-1) * -1);
    overflow-y: auto;
  }

  // Overrides the dropdown behaviour so the sidebar shows.
  .sidebar-nav {
    // stylelint-disable declaration-no-important
    display: block !important;
  }

  .btn-sidebar-toggle {
    display: none;
  }

  .sidebar-title-block {
    padding-top: $sidebar-title-padding-top;
    padding-left: 0.375rem;
    margin-top: 0;
    margin-bottom: $sidebar-title-margin-bottom;
  }
}
