.btn-scroll-top {
  position: absolute;
  right: var(--s-3);
  bottom: var(--s-3);
  z-index: 10;
}

.btn-scroll-top:hover {
  background-color: var(--primary);
}
