// If editing the base style of forms, use the _forms.scss file in core/components

.form {
  padding: $spacer;
  margin-top: $spacer;
  margin-bottom: $spacer;
  background-color: var(--gray-100);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
}

[data-bs-theme="dark"] .form {
  background-color: var(--gray-800);
}

input {
  max-width: 100%;
}

select {
  max-width: 100%;
}
