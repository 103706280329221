#header-search-container {
  max-width: $search-container-max-width;
}

#header-logo {
  margin-right: var(--s-2);

  img {
    height: 1.75rem;
  }
}

@include media-breakpoint-up(md) {
  #header-logo img {
    height: 2.25rem;
  }
}

#site-header-banner {
  height: 9rem;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
}

.site-search-icon {
  vertical-align: -0.1rem;
}
