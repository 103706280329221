@include color-mode(dark) {
  .list-group-radio {
    --hover-background-color: var(--gray-800);
  }
}

.list-group-radio {
  --hover-background-color: var(--gray-50);

  .list-group-item {
    cursor: pointer;
    border-radius: var(--border-radius);
  }
  .form-check-input {
    z-index: 2;
    margin-top: -0.5em;
  }

  .list-group-item:hover,
  .list-group-item:focus {
    background-color: var(--hover-background-color);
  }

  .form-check-input:checked ~ .list-group-item {
    background-color: var(--body);
    border-color: var(--primary);
    box-shadow: 0 0 0 2px var(--primary);
    transition: box-shadow 200ms ease-in-out;
  }
  .form-check-input[disabled] + .list-group-item,
  .form-check-input:disabled + .list-group-item {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }
}

@include color-mode(dark) {
  .check-panel {
    --hover-background-color: var(--gray-800);
  }
}

.check-panel {
  --hover-background-color: var(--gray-50);

  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);

  > a {
    color: inherit;
    /* stylelint-disable-next-line declaration-no-important */
    text-decoration: none !important;
  }

  &:hover {
    background-color: var(--hover-background-color);
  }

  &.checked {
    background-color: var(--body);
    border-color: var(--primary);
    box-shadow: 0 0 0 2px var(--primary);
    transition: box-shadow 200ms ease-in-out;
  }
}
