.content-list-article {
  --template-areas: "title" "date" "img" "desc" "link";
  --template-columns: 1fr;
  --border: 1px solid rgb(0 0 0 / 10%);
  --gap: 0;
  --spacing: var(--s-5);

  display: grid;
  grid-template-areas: var(--template-areas);
  grid-template-columns: var(--template-columns);
  gap: var(--gap);
  padding-bottom: var(--spacing);
  margin-bottom: var(--spacing);
  border-bottom: var(--border);

  & .article-title {
    grid-area: title;
    max-width: var(--mw-prose);
    margin: 0 0 0.75rem;

    & a {
      color: inherit;
      text-decoration: none;
    }
  }

  & .article-image {
    grid-area: img;
  }

  & .article-description {
    grid-area: desc;
    max-width: var(--mw-prose);
    display: grid;
    align-content: center;
  }
}

.article-image img {
  display: block;
  max-height: 200px;
  margin-inline: auto;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

@include media-breakpoint-up(md) {
  .content-list-article {
    --template-areas: "title title" "img desc";
    --template-columns: repeat(2, 1fr);
    --gap: 0 var(--s-3);
  }
}

@include media-breakpoint-up(lg) {
  .content-list-article {
    --template-areas: "img title title title" "img desc desc desc";
    --template-columns: repeat(4, 1fr);
    --gap: 0 2.5rem;
  }
}
