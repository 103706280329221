.text-page-grid {
  --grid-template-areas: "body" "sharing";
  --grid-columns: 1;
  --grid-rows: 1fr auto;
  --gap: var(--s-3);

  position: relative;
  display: grid;
  grid-template-areas: var(--grid-template-areas);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-template-rows: var(--grid-rows);
  gap: var(--gap);
}

@include media-breakpoint-up(lg) {
  .text-page-grid {
    --grid-template-areas: "sharing body body body" ". body body body";
    --grid-columns: 4;
    --grid-rows: auto 1fr;
    --gap: var(--s-3);
  }

  .text-page-sharing {
    position: sticky;
    top: var(--s-4);
  }
}

.text-page-sharing {
  grid-area: sharing;
}

.text-page-body {
  grid-area: body;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  ul,
  ol {
    max-width: var(--mw-prose);
  }
}
