// stylelint-disable declaration-no-important
@each $name, $_ in $pathway-colors {
  .text-pathway-#{$name} {
    color: var(--#{$name}-color) !important;
  }
}

@each $name, $_ in $pathway-bg-colors {
  .bg-pathway-#{$name} {
    background-color: var(--#{$name}-bg-color) !important;
  }
}

@each $name, $_ in $pathway-colors {
  .border-pathway-#{$name} {
    border-color: var(--#{$name}-color) !important;
  }
}
