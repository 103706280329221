.references {
  border-top: var(--border-color);
  padding-top: var(--s-5);
  margin-top: var(--s-5);
  font-size: var(--text-sm);
 }

.csl-entry {
  padding-left: 1.5rem;
  text-indent: -1.5rem;
}
