.border-thin-light,
.side-box,
.box {
  border: 1px solid var(--border-color);
}

.box,
.side-box {
  padding: 1rem;
  border-radius: 1rem;
}

.side-box {
  float: right;
  width: 30%;
  margin-bottom: var(--s-4);
  margin-left: var(--s-4);
}
