.icon-list {
  --icon: "\F270"; // check-2-circle by default
  --icon-font-family: "bootstrap-icons";
  --icon-color: var(--section-color);

  padding-inline-start: 0;
  list-style: none;

  li {
    position: relative;
    padding-inline-start: var(--s-4);
    padding-block-end: var(--s-3);
  }

  li::before {
    position: absolute;
    top: 0.4em;
    left: 0;

    display: inline-block;
    vertical-align: -0.125em;
    line-height: 1;

    content: var(--icon);
    font-family: var(--icon-font-family);
    font-weight: bold;
    font-size: 17px;

    color: var(--icon-color);
  }
}

.icon-list-check-2-circle {
  --icon: "\F270";
}

.icon-list-check-circle {
  --icon: "\F26B";
}

.icon-list-check-circle-fill {
  --icon: "\F26A";
}

