@include color-mode(dark) {
  .footer-wrapper {
    --background-color: #{$footer-background-color-dark};
    --text-color: #{$footer-text-color-dark};
    --text-hover-color: #{$footer-text-hover-color-dark};
  }
}

.footer-wrapper {
  --background-color: #{$footer-background-color};
  --text-color: #{$footer-text-color};
  --text-hover-color: #{$footer-text-hover-color};

  padding-top: $footer-padding-top;
  background-color: var(--background-color);

  @include headings {
    font-weight: $footer-header-font-weight;
  }

  section {
    padding-bottom: $footer-section-padding;
  }

  h4 {
    padding: 0;
    margin-top: $footer-header-margin-top;
    margin-bottom: $footer-header-margin-bottom;
    font-size: $footer-header-font-size;
  }
}

#site-links,
#group-links {
  a {
    font-size: $footer-link-font-size;
    color: var(--text-color);

    &:hover {
      color: var(--text-hover-color);
      text-decoration: $footer-link-text-decoration-hover;
      text-underline-offset: 0.1em;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    margin-bottom: 0.125rem;
  }
}

#footer-legal {
  font-size: var(--text-xxs);
}

#footer-branding {
  padding: var(--s-3) 0;
}

.site-footer-img {
  height: $site-footer-img-height;
  margin-bottom: $site-footer-img-margin-bottom;
}

#site-footer-logo-seeandlearn {
  height: 44px;
}

#site-donate-footer {
  padding: var(--s-3) 0;
  clear: both;
  font-size: var(--text-sm);
  color: var(--gray-800);
  background-color: var(--primary-200);
}

.preferences-container {
  padding: var(--s-3) 0;
  font-size: var(--text-sm);
}
