.bar-chart {
  width: 100%;
  height: 1.5rem;
  margin-top: var(--s-3);
  background-color: var(--success-600);
}

.bar-chart-bar {
  height: 1.5rem;
  background-color: var(--danger-700);
}
